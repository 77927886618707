<template>
  <MainArticle :article="article" :loading="loading"></MainArticle>
</template>
<script>
import MainArticle from "@/components/article/MainArticle";

export default {
  data() {
    return {
      loading: true
    };
  },
  name: "Article",
  components: {
    MainArticle
  },
  computed: {
    articleId() {
      return this.$route.params.id;
    },
    article() {
      return this.$store.getters["articles/getCurrentArticle"];
    }
  },
  mounted() {
    let scope = this;
    this.$store
      .dispatch("articles/retrieveArticle", this.articleId)
      .then(() => {
        scope.loading = false;
      });
  }
};
</script>
