<template>
  <v-skeleton-loader type="image, article" :loading="loading">
    <article class="article">
      <div class="main-article-div">
        <v-btn text fab class="close-btn" @click="backToHome">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <div class="img-container">
          <img
            v-if="article.media.length >= 1"
            class="article-img"
            :src="article.media[0].url"
          />
          <img
            v-else
            class="article-img"
            src="https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          />
        </div>
        <div class="article-container">
          <h5 v-bind:style="{ color: article.colour }" class="provider">
            {{ article.newsSource.toUpperCase() }}
          </h5>
          <hr
            v-bind:style="{ backgroundColor: article.colour }"
            class="main-article-accent"
          />
          <!-- <h6 class="date">{{ article.date }}</h6> -->
          <h2 class="title">{{ article.title }}</h2>
          <h5 class="subtitle">{{ article.subtitle }}</h5>
          <p
            class="story"
            v-if="!isExpanded"
            :inner-html.prop="article.text | truncate(250)"
          ></p>
          <a v-if="!isExpanded" class="see-details" @click="expandArticle"
            >See Details</a
          >
          <p v-if="isExpanded" class="story" v-html="article.text"></p>
        </div>
      </div>
    </article>
  </v-skeleton-loader>
</template>
<script>
export default {
  data() {
    return {
      isExpanded: false
    };
  },
  props: ["article", "loading"],
  methods: {
    backToHome() {
      this.$router.push({ name: "News" });
    },
    expandArticle() {
      this.isExpanded = !this.isExpanded;
    }
  },
  filters: {
    truncate: function(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "...";
      }

      return value;
    }
  }
};
</script>
<style scoped>
.title {
  /* Title */

  position: static;
  width: 327px;
  left: 0px;
  top: 38px;
  margin-bottom: 5px;

  font-family: Playfair Display !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 35px !important;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #323b3c;
}

.article-container {
  padding: 0.5rem;

  /* background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(48px); */
}

.subtitle {
  /* Sub-Title */

  position: static;
  width: 327px;
  left: 0px;
  top: 162px;
  margin-bottom: 8px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #516163;
}

.story {
  width: 327px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #516163;
}

.close-btn {
  position: absolute;
  right: 0px;
  z-index: 50;
}

.main-article-accent {
  position: static;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;
  border: none;
  margin-bottom: 5px;

  background-color: #5c0f64;
}

.article-img {
  /* Rectangle 6 */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

div.img-container {
  width: 110%;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  height: 400px;
}

.main-article-div {
  padding: 0rem !important;
}

.provider {
  /* Provider */

  position: static;
  /* width: 223px; */
  height: 20px;
  left: 0px;
  top: 0px;
  margin-bottom: 5px;
  margin-top: 10px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #5c0f64;
}

.home-title-accent {
  position: relative;
  border-bottom: 2px solid;
  display: inline;
  padding-top: 50px;
  margin: 0;
}

.date {
  /* Date */

  position: static;
  height: 20px;
  left: 0px;
  top: 30px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height, or 192% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #1b2475;
}

.see-details {
  position: static;
  width: 76px;
  height: 20px;
  left: 0px;
  top: 202px;
  margin-top: 5px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;

  text-decoration-line: underline;

  /* NESCOL */

  color: #4e2a69 !important;
}
</style>
